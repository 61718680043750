import { frontendURL } from '../../../../helper/URLHelper';
const SettingsWrapper = () => import('../SettingsWrapper.vue');
const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/knowledges'),
      component: SettingsWrapper,
      meta: {
        permissions: ['administrator'],
      },
      children: [
        {
          path: '',
          name: 'knowledge_settings',
          component: Index,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
  ],
};
