export const state = {
  uploadedFile: null,
  isUploaded: false,
};

export const mutations = {
  setUploadedFile(state, file) {
    state.uploadedFile = file;
  },
  setIsUploaded(state, isUploaded) {
    state.isUploaded = isUploaded;
  },
};

export const getters = {
  uploadedFile: state => state.uploadedFile,
  isUploaded: state => state.isUploaded,
};

export const actions = {
  handleFileUpload({ commit }, file) {
    commit('setUploadedFile', file);
    commit('setIsUploaded', true);
  },
  handleRemoveFile({ commit }) {
    commit('setUploadedFile', null);
    commit('setIsUploaded', false);
  },
  handleDrop({ dispatch }, event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    dispatch('processFiles', files);
  },
  processFiles({ commit }, files) {
    const validFileTypes = [
      'text/plain', // .txt
      'text/markdown', // .md
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    ];
    const maxFileSize = 15 * 1024 * 1024;

    const validFiles = Array.from(files).filter(file => {
      return validFileTypes.includes(file.type) && file.size <= maxFileSize;
    });

    if (validFiles.length !== files.length) {
      alert('Some files were not accepted due to invalid type or size.');
    }

    // Commit the valid files to the state
    if (validFiles.length > 0) {
      commit('setUploadedFile', validFiles[0]); // Assuming only one file will be handled
      commit('setIsUploaded', true);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};